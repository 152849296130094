<template>
     <v-container>
        <h3 class="mb-4">
            Export all classifications for reach
        </h3>
        <date-range-picker
            ref="picker"
            opens="right"
            :minDate="null"
            :maxDate="null"
            :singleDatePicker="false"
            :timePicker="false"
            :timePicker24Hour="false"
            :showWeekNumbers="true"
            :showDropdowns="true"
            :autoApply="true"
            v-model="selectedDateRange"
            @update="onDateSelected"
            @toggle="exportAllDateRange = false"
        ></date-range-picker>
            
        <v-row class="align-center">
            <v-col cols="6">
              <!-- River Dropdown -->
                <v-autocomplete
                    v-model="selectedRiver"
                    :items="riverDropdownItems"
                    item-value="id"
                    item-text="title"
                    label="River"
                    hint="Select the river"
                    :loading="riverDropdownAPIStatus_Pending"
                    persistent-hint
                ></v-autocomplete>
            </v-col>
            <v-col cols="6">
                <!-- Polygon Dropdown (first select river)-->
                <v-select
                    v-model="selectedPolygons"
                    :items="polygonDropdownItems"
                    label="Reach"
                    item-value="id"
                    item-text="title"
                    hint="Select the reach you wish to export"
                    persistent-hint
                    :disabled="!selectedRiver"
                    multiple
                    :loading="polygonDropdownAPIStatus_Pending"
                >
                </v-select>
            </v-col>
        </v-row>


        <v-row>
            <v-col cols="auto">
                <v-checkbox v-model="exportAllDateRange" label="Export All Dates"></v-checkbox>
            </v-col>
            <v-col cols="auto">
                <v-checkbox v-model="exportOnlyCurrentUser" label="Export Only Current User"></v-checkbox>
            </v-col>


        </v-row>

        <v-row class="mt-0">
            <v-btn
                @click="exportAllPolygonsByRiverId"
                color="primary"
                large
                :disabled="exportClassificationButtonDisabled"
                :loading="exportClassificationAPIStatus_Pending"
                class="text-capitalize ma-3"
            >
                    Export All Classifications
                    <v-icon right>mdi-download</v-icon>
            </v-btn>
        </v-row>
    </v-container> 
</template>

<script>
    import { withAsync } from               "@/helpers/withAsync";
    import { apiStatus } from               "@/api/constants/apiStatus";
    import { apiStatusComputed } from       "@/api/helpers/computedApiStatus";
    import { exportClassification } from    "@/api/adminApi.js";
    import { mapGetters } from              "vuex";
    import { getUserRivers, getPolygonsByRiverId } from           "@/api/userApi.js";
    import { getAllRivers, getUserPolygons } from "@/api/polygonApi.js";
    import DateRangePicker from 'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    import { formatDate } from "@/helpers/dateFormatter.js"

    export default {
        name: "ExportClassificationsForReach",
        components: { DateRangePicker },


        data() {
            return {
                // For the river dropdown.
                riverDropdownItems: [],
                riverDropdownAPIStatus: apiStatus.Idle,
                selectedRiver: null,

                // For the polygon dropdown.
                polygonDropdownItems: [],
                polygonDropdownAPIStatus: apiStatus.Idle,
                selectedPolygons: [],

                // For the export button.
                exportClassificationAPIStatus: apiStatus.Idle,
                selectedDateRange: {
                    startDate: null,
                    endDate: null
                },
                //areAllSelected: false,
                exportAllDateRange: true,
                exportOnlyCurrentUser: false
            }
        },

        computed: {
            ...apiStatusComputed(["exportClassificationAPIStatus", "riverDropdownAPIStatus", "polygonDropdownAPIStatus"]),
            ...mapGetters([
                "getAllUsers",
                "getAllPolygonsByRiver",
                "getCurrentUserId"
            ]),
            
            exportClassificationButtonDisabled() {
                return !this.selectedPolygons.length || !((!!this.selectedDateRange.startDate && !!this.selectedDateRange.endDate) || this.exportAllDateRange);
            },

            users() {
                return this.getAllUsers;
            },
        },

        methods: {
            onDateSelected( selectedDate ) {
                this.selectedDateRange.startDate = formatDate(selectedDate.startDate);
                this.selectedDateRange.endDate = formatDate(selectedDate.endDate);
            },

            async exportAllPolygonsByRiverId() {
                this.exportClassificationAPIStatus = apiStatus.Pending;
                const body =
                    {
                        "polygon_ids": this.selectedPolygons,
                        "start_date": this.exportAllDateRange ?  undefined : this.selectedDateRange.startDate, // not required
                        "end_date":  this.exportAllDateRange ?  undefined : this.selectedDateRange.endDate, // not required
                        "user_only": this.exportOnlyCurrentUser
                    };

                const { response, error } = await withAsync(
                    exportClassification, 
                    body
                );
                
                if( error ) {
                    this.exportClassificationAPIStatus = apiStatus.Error;
                    console.log('error', error);
                    this.$emit("export-error", { "error": true, "errorMessage": "No classifications found for the provided parameters" })
                    this.reset();
                    return;
                }

                // Extract filename from Content-Disposition header
                let filename = "classifications.xlsx";
                const contentDisposition = response.headers['content-disposition'];
                if (contentDisposition && contentDisposition.indexOf('attachment') !== -1) {
                    const filenameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                    if (filenameMatch != null && filenameMatch[1]) {
                        filename = filenameMatch[1].replace(/['"]/g, '');
                    }
                }

                var FILE = window.URL.createObjectURL(new Blob([response.data], { type: response.headers.contentType }));
                var link = document.createElement('a');
                link.href = FILE;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                this.exportClassificationAPIStatus = apiStatus.Success;
                this.reset();
            },

            // toggleUserSelect() {
            //     if (this.selectedPolygons.length === this.getAllUsers.length) {
            //         this.selectedPolygons = [];   
            //         return;
            //     }           
            //     this.selectedPolygons = this.getAllUsers.map(user => user.id);
            // },

            reset() {
                this.selectedPolygons = [];
            },

            // Api call to get all polygons by river id
            async getPolygonsByRiverId( riverId ) {
                this.polygonDropdownAPIStatus = apiStatus.Pending;
                let result;
                if (this.exportOnlyCurrentUser) {
                    result = await withAsync(getUserPolygons, riverId);
                    // {{api_url}}/user/polygons?river_id=120
                } else {
                    result = await withAsync(getPolygonsByRiverId, riverId);
                }
                
                if( result.error ) {
                    this.polygonDropdownAPIStatus = apiStatus.Error;
                    return null;
                }

                this.polygonDropdownAPIStatus = apiStatus.Success;
                this.polygonDropdownItems = result.response.data.data.map(polygon => ({
                    id: polygon.polygon_id,
                    title: polygon.name
                }));
            },

            // Api call to get all registered rivers of the user
            async getAllRivers() {
                this.riverDropdownAPIStatus = apiStatus.Pending;

                let result;
                if (this.exportOnlyCurrentUser) {
                    result = await withAsync(getUserRivers, this.getCurrentUserId);
                } else {

                    const payload = {
                        items: 200,
                        search: this.selectedRiver
                    }
                    result = await withAsync(getAllRivers, payload.items, null, payload.search);
                }

                if( result.error ) {
                    this.riverDropdownAPIStatus = apiStatus.Error;
                    return null;
                }

                this.riverDropdownAPIStatus = apiStatus.Success;             
                this.riverDropdownItems = result.response.data.data.map(river => ({
                    id: river.river_id,
                    title: river.river_name
                }));
            },

            // Function to handle selected river change
            handleSelectedRiverChange(newVal, oldVal) {

                !!this.selectedRiver && this.getPolygonsByRiverId( this.selectedRiver );
            }
        },

        watch: {
            exportOnlyCurrentUser(val) {
                this.riverDropdownItems = [];
                this.selectedPolygons = [];
                this.selectedRiver = null;
                this.getAllRivers();

            },

            exportAllDateRange(newVal, oldVal) {
                if (newVal === true) {
                    this.selectedDateRange = {
                        startDate: null,
                        endDate: null
                    }
                }
            },

            selectedRiver(newVal, oldVal) {
                // This function will be called when `selectedRiver` changes.
                // `newVal` is the new value, `oldVal` is the old value.
                this.handleSelectedRiverChange(newVal, oldVal);
            },
        },

        created() {
            this.getAllRivers();
        }
    }
</script>
<template>
  <div id="app">
    <success-alert
        v-if="success"
        :success="success"
        :successMessage="successMessage"
        class="d-flex justify-center font-weight-black white--text width"
        @success-closed="success=false"
      >
        <template #success-message>
          {{ successMessage }}
        </template>
      </success-alert>

      <ErrorAlert
        :error="error"
        :error-message="errorMessage"
        @error-closed="error=false"
      />
      
      <!-- Card with all the info of the selected user -->

    <v-card
      v-if="$store.state.Admin.currentUserId[0]?.name"
      class="my-5"
      :class="screenWidth > 870 ? 'margin' : ''"
      elevation="1"
      :width="screenWidth >= 870 ? '65%' : '100%'"
    >
    <v-row>
      <v-col
        lg="6"
        cols="12"
      >
        <span class="ml-5 mb-5 text-color font-rubik"><strong> Name: </strong>{{ $store.st  [0]?.name }} </span>
        <br />
        <span class="ml-5 mb-5 text-color font-rubik"><strong> Role: </strong>{{ $store.state.Admin.currentUserId[0]?.role }}</span>
        <br />
        <span class="ml-5 mb-5 text-color font-rubik"><strong> Email: </strong>{{ $store.state.Admin.currentUserId[0]?.email }}</span>
        <br />
      </v-col>
      <v-col
        lg="6"
        cols="12"
      >
        <v-card-text
          class="d-flex justify-end text-color font-rubik"
        >
          <span
            class="mt-3 font-weight-bold"
            :class="{ 'text-green': $store.state.Admin.currentUserId[0]?.active, 'text-orange': !$store.state.Admin.currentUserId[0]?.active }"
          >{{ $store.state.Admin.currentUserId[0]?.active ? "Active" : "Inactive" }} </span>
        </v-card-text>
        <!-- Admin can: Send verification email to user, de-activate the user -->
        <v-card-actions  class="d-flex justify-end">
          <template v-if="$store.state.Admin.currentUserId[0]?.active === true">
            <v-tooltip left v-if="hideForFirstRelease">
              <template v-slot:activator="{ on }">
                <v-btn :disabled="!isAdmin" class="mr-2" @click="sendVerificationEmail" v-on="on">
                  <v-icon color="#19A7CE">mdi-email-outline</v-icon>
                </v-btn>
              </template>
              <span>Send Verification Email</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn :disabled="!isAdmin" @click="deactivateAccount" v-on="on">
                  <v-icon color="#19A7CE">mdi-account-cancel-outline</v-icon>
                </v-btn>
              </template>
              <span>Deactivate Account</span>
            </v-tooltip>
          </template>
          <template v-else >
            <v-tooltip left v-if="hideForFirstRelease">
              <template v-slot:activator="{ on }">
                <v-btn @click="sendVerificationEmail" v-on="on">
                  <v-icon color="#19A7CE">mdi-email-outline</v-icon>
                </v-btn>
              </template>
              <span>Send Verification Email</span>
            </v-tooltip>
            <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn @click="activateAccount" v-on="on">
                  <v-icon color="#19A7CE">mdi-account-outline</v-icon>
                </v-btn>
              </template>
              <span>Activate Account</span>
            </v-tooltip>
          </template>
        </v-card-actions>
      </v-col>
    </v-row>
    </v-card>
  </div>
</template>

<script>
  import { withAsync } from               "@/helpers/withAsync";
  import { apiStatus } from               "@/api/constants/apiStatus";
  import { apiStatusComputed } from       "@/api/helpers/computedApiStatus";
  import { apiObject } from               "@/api/api";
  import { activateUser, deactivateUser, verifyUser } from "@/api/adminApi.js";
  import { mapGetters } from "vuex";
  import SuccessAlert from "@/components/SuccessAlert.vue";
  import ErrorAlert from "@/components/ErrorAlert.vue";

  export default {
    components: { ErrorAlert },

    name: "UserInfoCard",
    data(){
      return {
        error: false,
        errorMessage: "",
        successMessage: "",
        success:false,
        screenWidth: null,
        activateUser: apiStatus.Idle,
        deactivateUser: apiStatus.Idle,
        verifyUser: apiStatus.Idle,
        hideForFirstRelease: true,
      }
    },

    computed: {
    ...apiStatusComputed(["activateUser", "deactivateUser", "verifyUser"]),
    ...mapGetters([
        'isAdmin'
    ]),

      // If the user has already verified their account, no email is being sent
      alreadyVerified(){
        return this.successMessage === "Your email address has already been verified.";
      },
    },
    methods: {

      handleResize() {
        this.screenWidth = window.innerWidth;
      },

      // Api call to send the verification email to selected user
      async sendVerificationEmail(){
        this.success = false;
        this.successMessage = "";
        this.verifyUser = apiStatus.Pending;

        let payload = {
          email: this.$store.state.Admin.currentUserId[0].email
        }

        const { response, error } = await withAsync(verifyUser, payload);

        if (error) {
          console.log(error);
          this.error = true;
          this.verifyUser = apiStatus.Error;
          this.errorMessage = error.response.data.message;
          if (error.response.status == 401) {
            this.$router.push('/login');
          }
          this.loading = false;
          return this.errorMessage;
        }

        this.verifyUser = apiStatus.Success;
        this.success = true;
        
        this.error = false;
        this.loading = false;
        let apiResponse = response;
        this.successMessage = response.data.metadata.response_message;
      },

      // Api call to deactivate user's account (available only if the account is active)
      async deactivateAccount(){
        this.success = false;
        this.successMessage = "";
        this.deactivateUser = apiStatus.Pending;

        let payload = {
          user_id: this.$store.state.Admin.currentUserId[0].id
        }

        const { response, error } = await withAsync(deactivateUser, payload);

        if (error) {
          console.log(error);
          this.error = true;
          this.deactivateUser = apiStatus.Error;
          this.errorMessage = error.response.data.message;
          if (error.response.status == 401) {
            this.$router.push('/login');
          }
          this.loading = false;
          return this.errorMessage;
        }

        this.deactivateUser = apiStatus.Success;
        this.success = true;
        
        this.error = false;
        this.loading = false;
        let apiResponse = response;
        this.$store.dispatch("currentUserId", [apiResponse.data.data])
        this.successMessage = "This account has been deactivated";
      },

      // Api call to activate the deactivated user's account (available only if the account is deactivated)
      async activateAccount(){
        this.success = false;
        this.successMessage = "";
        this.activateUser = apiStatus.Pending;

        let payload = {
          user_id: this.$store.state.Admin.currentUserId[0].id
        }

        const { response, error } = await withAsync(activateUser, payload);

        if (error) {
          console.log(error);
          this.error = true;
          this.activateUser = apiStatus.Error;
          this.errorMessage = error.response.data.message;
          if (error.response.status == 401) {
            this.$router.push('/login');
          }
          this.loading = false;
          return this.errorMessage;
        }

        this.activateUser = apiStatus.Success;
        
        this.error = false;
        this.loading = false;
        let apiResponse = response;
        this.$store.dispatch("currentUserId", [apiResponse.data.data]);
        this.success = true;
        this.successMessage = "This account has been activated";
      },
    },

    created() {
      // Set the initial screenWidth value
      this.screenWidth = window.innerWidth;
      // Add event listener for window resize
      window.addEventListener("resize", this.handleResize);
    },
    
    updated(){
      this.screenWidth = window.innerWidth;
    },

    beforeDestroy() {
      // Remove the event listener when the component is destroyed
      window.removeEventListener("resize", this.handleResize);
    },
  }
</script>

<style scoped>
  .font-rubik{
    font-family: 'Rubik', sans-serif;
    font-size: 25px !important;
  }
  .text-color{
    color: #19A7CE !important;
  }
  .text-green {
    color: rgb(6, 200, 113) !important;
  }
  .text-orange {
    color: rgb(231, 178, 80) !important;
  }
  .width{
    width: 360px;
    display: flex;
    margin: 0 auto;
  }
  .margin{
    display: flex;
    margin: 0 auto;
    margin-left: 25%;
  }
</style>
<template>
  <div>
    <v-btn
      elevation="0"
      color="transparent"
      class="margin menu-btn"
      @click="drawer = !drawer"
    >
      <v-icon color="#19a7ce">mdi-menu</v-icon>
    </v-btn>

    <v-navigation-drawer
      v-model="drawer"
      color="#19a7ce"
      width="320"
      app
    >
      <v-list style="height: 100%;" class="d-flex flex-column justify-space-between">
        <div>
            <v-img contain
            max-width="280"
              @click="goToRiverTemp"
              src="@/assets/logoHor.png"/>
        <!------------------------------------------------------
          EXPLORE SENTINEL-2 IMAGES
        ------------------------------------------------------->
        <BaseMenuListItem
          link="/"
          icon="mdi-map"
          label="Explore Sentinel-2 Images"
        />
        
        <!------------------------------------------------------
          EXPORT CLASSIFICATION BUTTON
        ------------------------------------------------------->
        <ExportsButton class="mx-5 my-4"/>
      
        <!------------------------------------------------------
          SIGNED IN USER INFO
        ------------------------------------------------------->
        <div class="mx-5">
            <span class="text-sm white--text">
              Signed in as: 
              <span class="font-weight-bold">
                {{ getCurrentUser }}
              </span>
            </span>
        </div>

        <!------------------------------------------------------
          LOGOUT BUTTON
        ------------------------------------------------------->
        <BaseMenuListItem
          @click="logout()"
          icon="mdi-power"
          label="Logout"
        />

        <!------------------------------------------------------
          POLYGON LIST
        ------------------------------------------------------->
        <BaseMenuPolygonList class="mt-7"/>
      
      </div>
        

        <div>
        <!------------------------------------------------------
          MOOC
        ------------------------------------------------------->
        <BaseMenuListItem
        class="mt-4"
          link="#"
          icon="mdi-laptop"
          label="MOOC"
        />

        <!------------------------------------------------------
          ABOUT THE PROJECT
        ------------------------------------------------------->
        <BaseMenuListItem
          link="https://www.rivertemp.eu/en/the-project/"
          icon="mdi-information"
          label="About the Project"
        />

        <!------------------------------------------------------
          USER GUIDE
        ------------------------------------------------------->
        <BaseMenuListItem
          link="#"
          icon="mdi-file"
          label="User Guide"
        />
      </div>
      </v-list>
    </v-navigation-drawer>

    <router-view></router-view>
  </div>
</template>

<script>
  import { withAsync } from               "@/helpers/withAsync";
  import { apiStatus } from               "@/api/constants/apiStatus";
  import { apiStatusComputed } from       "@/api/helpers/computedApiStatus";
  import { logoutUser } from              "@/api/authenticationApi.js"  
  import { mapGetters, mapActions } from  "vuex";
  import ExportsButton from               "@/views/Admin/ExportsButton.vue";
  import BaseMenuListItem from            "@/components/BaseMenuListItem.vue";
  import BaseMenuPolygonList from           "@/components/BaseMenuPolygonList.vue";

  export default {
    name: "SideBarLayout",
    components: { ExportsButton, BaseMenuListItem, BaseMenuPolygonList },

    data() {
      return {
        drawer: true,
        clicked: false,
        loading: true,
        currentPage: 1,
        items: [],
        order: "desc",
        lastPage: null,
        allUservInfo: null,
        selectedUserId: null,
        filters: [
          {
            title:"Date of Registry",
            value: "id" 
          },
          {
            title:"Role",
            value: "role" 
          },
          {
            title:"State",
            value: "active" 
          },
          {
            title:"Name",
            value: "username" 
          },
        ],
        selectedFilter: null,
        screenWidth: window.innerWidth,
        logoutUser: apiStatus.Idle,
        getAllUsers: apiStatus.Idle,
      };
    },

    computed: {
      ...apiStatusComputed([ "logoutUser", "getAllUsers" ]),
      ...mapGetters([ "getCurrentUser" ]),
    },

    methods: {

      //changeOrder(){
        //this.order === "desc" ? this.order = "asc" : this.order = "desc";
        //this.getRegisteredUsers();
      //},

      // async getRegisteredUsers() {
      //   this.items = [];
      //   const queryParams = {
      //     page: this.currentPage,
      //     items: 8,
      //     order: this.order,
      //     order_by: this.selectedFilter ? this.selectedFilter : ""
      //   }

      //   this.getAllUsers = apiStatus.Pending;

      //   const { response, error } = await withAsync(getAllUsers, { params: queryParams });

      //     if (error) {
      //       console.log(error);
      //       this.error = true;
      //       this.getAllUsers = apiStatus.Error;
      //       this.errorMessage = error.response.data.message;
      //       if (error.response.status == 401) {
      //         this.$router.push('/login');
      //       }
      //       return this.errorMessage;
      //     }

      //   this.getAllUsers = apiStatus.Success;

      //   this.error = false;
      //   let apiResponse = response.data.data;
      //   this.allUserInfo = apiResponse;
      //   this.items = apiResponse.map((user) => ({
      //     title: user.name,
      //     id: user.id,
      //   }));
      //   this.lastPage = response.data.meta.last_page;
      //   this.setAllUsers(this.items);
      // },

      // Set the selected user
      // navigateToAdminPanel(userId) {
      //   this.selectedUserId = userId;
      //   localStorage.setItem("userId", userId);
      //   this.$store.dispatch("currentUserId", this.allUserInfo.filter((item)=>item.id === userId));
      //   if(this.screenWidth < 1264){
      //     this.drawer = false;
      //   }
      // },

      async logout(){
        this.logoutUser = apiStatus.Pending;

        const {response, error} = await withAsync(logoutUser);
        if(error){              
          this.logoutUser = apiStatus.Error;
        }

        this.$store.dispatch("logout");
        this.$store.dispatch("eraseAdminSelectedUser");

        this.logoutUser = apiStatus.Success;

        this.$router.push("/login");
      },

      goToRiverTemp() {
        this.$router.push("/");
      }
    },

    mounted(){
      this.screen = window.screen;
      // this.getRegisteredUsers();
    },
  };
</script>

<style scoped>
  body {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  .margin{
    display: flex;
    margin: 0 15%;
  }
  .arrow-btn{
    display: flex;
    margin: 5% auto;
  }
  .font {
    font-family: "Inter", sans-serif;
    font-weight: 600;
    font-size: 18px;
  }
  .icon-width{
    width: 30px;
  }
  .selected {
    background-color: white !important;
    border-radius: 9999px;
    width: 300px;
    margin-left: 8px;
    box-shadow: -7px 1px rgba(41, 40, 40, 0.265);
  }
  .selection{
    color:#19a7ce !important;
  }
  ::v-deep .theme--light.v-navigation-drawer .v-divider {
    border-color: rgb(255 255 255);
  }

  @media only screen and (max-width: 1495px){
    .margin{
      margin: 0 2%;
    }
  }

  @media only screen and (min-width: 1264px) {
    .menu-btn {
      display: none;
    }
  }
</style>
<template>
  <div id="app">
    <UserInfo />
  <ExpansionPanelPolygon   
      :polygons="polygons"
      :riverid="this.selectedRiverId"
      @metadataUpdated="getPolygonsBySelectedRiver"
    />

    <div
    class="text-center"
      v-if="getRiverPolygonsStatus_Pending && polygons.length === 0"      
    >
    <v-progress-circular
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
    </div>
    
    <!-- Note instead of expansion panels if the selected user has no images saved -->
    <h1
      v-else-if="polygons.length > 0 && (screenWidth < 560)"
      class="text-color font-rubik text-center text-h5 font-italic"
    >
      Please rotate your device to see the registered rivers.
    </h1>
     <!-- else if for register user  -->
    <!-- <h1
      v-else-if="$store.state.Admin.currentUserId && polygons.length === 0"
      class="text-color font-rubik text-center text-h5 font-italic"
    >
      This user has no registered rivers at the moment
    </h1> -->
    <h1
      v-else-if="selectedRiverId != null && polygons.length === 0"
      class="text-color font-rubik text-center text-h5 font-italic"
    >
      No registered polygons at the moment
    </h1>
  </div>
</template>

<script>
  import { withAsync } from               "@/helpers/withAsync";
  import { apiStatus } from               "@/api/constants/apiStatus";
  import { apiStatusComputed } from       "@/api/helpers/computedApiStatus";
  import { getPolygonsByRiverId } from "@/api/adminApi.js";
  import UserInfo from                    "./UserInfo.vue";
  import ExpansionPanelPolygon from '@/views/RiverTemp/ExpansionPanelPolygon.vue';
  import { mapGetters } from "vuex";

  export default {
    name: "RiverPolygonList",
    components: { UserInfo, ExpansionPanelPolygon },

    data() {
      return {
        currentPage:1,
        selectedRiverId: null,
        itemsPerPage: 5,        
        polygons: [],       
        error: null,
        screenWidth: null,        
        getRiverPolygonsStatus: apiStatus.Idle,
      }
    },

    computed: {
      ...mapGetters({
        selectedRiver: 'getSelectedRiver',
      }),

      ...apiStatusComputed(["getRiverPolygonsStatus"])
    },

    methods: {      
      handleResize() {
        this.screenWidth = window.innerWidth;
      },

      //get polygon by id 
      async getPolygonsBySelectedRiver(id) { 
        this.polygons = [];
        this.getRiverPolygonsStatus = apiStatus.Pending;
           const { response, error } = await withAsync(getPolygonsByRiverId, id);

        if( error ) {
          this.error = true;
          this.getRiverPolygonsStatus = apiStatus.Error;
          this.errorMessage = error.response.data.message;
          if (error.response.status == 401) {
            this.$router.push('/login');
          }          
          return this.errorMessage;
        }

        this.getRiverPolygonsStatus = apiStatus.Success;
        this.error = false;        
        this.polygons = response.data.data;   
      },

      handleSelectedRiverChange(newVal, _) {
        this.selectedRiverId = newVal;
        this.getPolygonsBySelectedRiver(newVal);
      },
    },

    watch: {
      selectedRiver(newVal, oldVal) {
        // This function will be called when `selectedRiver` changes.
        // `newVal` is the new value, `oldVal` is the old value.
        this.handleSelectedRiverChange(newVal, oldVal);
      },
    },

    created() {
      this.screenWidth = window.innerWidth;

      window.addEventListener("resize", this.handleResize);
    },
    
    updated(){
      this.screenWidth = window.innerWidth;
    },

    beforeDestroy() {
      window.removeEventListener("resize", this.handleResize);
    },
  };
</script>
<style scoped>
  .custom{
    display: flex;
    margin: 0 auto;
    margin-left: 25%;
    width: 65% !important;
  }
  .font-rubik{
    font-family: 'Rubik', sans-serif;
    font-size: 20px !important;
  }
  .text-color{
    color: #19A7CE !important;
  }
  .margin{
    display: flex;
    margin: 0 auto;
  }
  .height{
    height: 1400px !important;
  }
  .small-height{
    height: 960px;
  }
  .custom-small{
    width: 100% !important;
  }
  .small-img{
    display: flex;
    margin-right: 80%;
  }
  >>>.v-dialog--active {
  display: flex !important;
  margin: 0 auto !important;
  }
  >>>.mdi-chevron-down::before, >>>.mdi-chevron-down::after, >>>.mdi-chevron-up::before, >>>.mdi-chevron-up::after {
    color: #19A7CE !important;
  }
  >>>.mdi-chevron-right::before, >>>.mdi-chevron-left::before  {
    background-color: transparent !important;
    color: #19A7CE !important;
  }
  >>>.mdi-minus::before {
    color: #19A7CE !important;
  }
  >>>.theme--light.v-text-field > .v-input__control > .v-input__slot:before, >>>.theme--light.v-text-field > .v-input__control > .v-input__slot:after {
    border-color: white !important;
  }
  >>>.theme--light.v-pagination .v-pagination__item {
    color: #19A7CE !important;
  }
  >>>.theme--light.v-pagination .v-pagination__item--active {
    color: #fff !important;
  }

  @media only screen and (max-width:570px){
    .font-rubik{
      font-size: 15px !important;
    }
  }
</style>
import { api } from "./api";
import CONFIG from "@/common/config";

const URLS = {
    allUsers: "/users",
    userImages: "/user",
    userRivers: "/user",
    currentUserPolygons: "/user",
    imageMetadata: "/user",
    activateUser: "/user/activate",
    deactivateUser: "/user/deactivate",
    emailVerification: "/email/verification-notification",
    multiUserMetadata: "image/metadata",
    singleUserRangeMetadata: "image/user-metadata",
};

export const getAllUsers = (config) => {
    return api.get(`${URLS.allUsers}`, config);
};

export const activateUser = (payload) => {
    return api.post(`${URLS.activateUser}`, payload);
};

export const deactivateUser = (payload) => {
    return api.post(`${URLS.deactivateUser}`, payload);
};

export const verifyUser = (payload) => {
    return api.post(`${URLS.emailVerification}`, payload);
};

export const getUserRivers = (id) => {
    return api.get(`${URLS.userRivers}/${id}/rivers`);
};

export const getPolygonsByRiverId = (id) => {
    return api.get(`river/${id}/polygons`);
};

export const getUserPolygons = (id, config) => {
    return api.get(`${URLS.currentUserPolygons}/${id}/polygons`, config);
};

export const getMultiUserMetadata = (selectedUsers, responseType) => {
    const queryParams = selectedUsers.reduce((acc, curr) => {
        acc += `user_ids[]=${curr}&`;
        return acc;
    }, "");

    return api.get(`${URLS.multiUserMetadata}?${queryParams}`, responseType);
};

export const exportClassification = (payload) => {
    return api.post(
        `classification-export`,
        { ...payload },
        { responseType: "blob" }
    );
};

export const getSingleUserRangeMetadata = (payload, responseType) => {
    return api.get(
        `${URLS.singleUserRangeMetadata}?start_date=${payload.start_date}&end_date=${payload.end_date}`,
        responseType
    );
};

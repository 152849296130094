<template>
  <v-main id="app" >
    <h2 v-if="selectedRiverName" class="my-10 mx-5 d-flex justify-center color">{{ selectedRiverName }}</h2>
    <h2 v-else class="my-10 mx-5 d-flex justify-center color">
      Choose a river from the list to see their registered work
    </h2>

    <!-- {{ xe.title }} -->
    
    <!-- <v-select
      outlined
      v-model="xe"
      label="* Image's Date"
      :items="[{id: 1, title: 'sdf'}, {id: 2, title: 'sdf'}, {id: 3, title: 'sdf'}]"
      color="primary"
      @input="handleDateDropdownChange"
    >
    </v-select> -->

    <RiverPolygonList class="pa-6"/>
  </v-main>
</template>

<script>
import RiverPolygonList from '@/views/Admin/RiverPolygonList.vue'
import { mapGetters } from "vuex";

export default {
  components: {RiverPolygonList},

  name: "AdminPage",

  data(){
    return{
      xe: null,
      userId: null,

    }
  },
  computed: {
         ...mapGetters({
        selectedRiverName: 'getSelectedRiverName',
      }),
    },
  methods: {
    handleDateDropdownChange(){
      console.log(this.xe)
    }
  },  
  created() {
    this.userId = this.$route.params.userId;
  },
}
</script>

<style scoped>
  .color{
    color: #19A7CE;
  }
</style>
<template>
  <div>
    <v-btn
      block
      large outlined 
      color="white" 
      @click="openDialog=true" 
      class="text-capitalize"
    >
      Export Classifications
    </v-btn>

    <v-dialog
      v-model="openDialog"
      width="1000"
   
      class="my-dialog"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="headline pt-2">Export Classifications</span>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <v-col cols="12">                
                <ExportClassificationsForReach
                  @export-error="handleError($event)"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="text-capitalize" color="primary" x-large text @click="openDialog = false">Close</v-btn>
        </v-card-actions>
        
      </v-card>
      <error-alert
        style="width: 500px;"
        :error="error"
        :error-message="errorMessage"
        @error-closed="error=false"
    ></error-alert>
    </v-dialog>
  </div>
</template>

<script>
    import ExportClassificationsByDate from "@/components/ExportClassificationsByDate.vue";
    import ExportClassificationsForReach from "@/components/ExportClassificationsForReach.vue";
    import ErrorAlert from "@/components/ErrorAlert.vue";

    export default {
        name: 'ExportsButton',
        components: { ExportClassificationsByDate, ExportClassificationsForReach, ErrorAlert },

        data() {
            return {
              openDialog: false,
              error: false,
              errorMessage: "",
            }
        },

        methods: {
            handleError(event) {
                this.error = event.error;
                this.errorMessage = event.errorMessage;
            }
        }
    }
</script>

<style scoped>
</style>
<script>
export default {
    name: 'BaseMenuListItem',

    props: {
        link: {
            type: String,
            default: null
        },
        icon: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            required: true
        }
    },

    methods: {
        handleClick(event) {
            this.$emit('click', event);
        },
    },
}
</script>

<template>
    <div>
        <v-list-item
          link
          :to="link"
          @click="handleClick"
        >
            <v-list-item-icon
                v-if="icon"
            >
                <v-icon class="white--text">
                    {{ icon }}
                </v-icon>
            </v-list-item-icon>

            <v-list-item-content
                v-if="label"
                class="white--text font"
            >
                {{ label }}
            </v-list-item-content>
        </v-list-item>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { getAllRivers } from "@/api/polygonApi.js";
import { apiStatus } from "@/api/constants/apiStatus";
import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
import { withAsync } from "@/helpers/withAsync";
import BaseMenuListItem from "@/components/BaseMenuListItem.vue";

export default {
    name: 'BaseMenuReachList',

    components: { BaseMenuListItem },
    data() {
        return {
            rivers: [],
            riverPage: 1,
            riversPerPage: 5,
            activeRiverId: null, // The ID of the currently active river
            getAllRiversStatus: apiStatus.Idle,
            paginationLength: null,
        };
    },
    computed: {
        ...apiStatusComputed("getAllRiversStatus"),
        ...mapGetters([
            "getCurrentUserData",
        ]),
        },

    methods: {
        ...mapActions(['selectRiverFromSidemenu', 'selectedRiverNameFromSidemenu']),

        selectRiverById( riverId, riverName ) {

            this.activeRiverId = riverId;            
                       
            this.selectRiverFromSidemenu( riverId );
            this.selectedRiverNameFromSidemenu( riverName );       

            if(this.screenWidth < 1264){
                this.drawer = false;
            }
        },
             //get paginated Rivers
        async fetchPaginatedRivers() {

        //set to null cause when change with next - prev or number 
        //in pagination showing prev rivers and skeleton
            this.rivers = null; 

            this.getAllRiversStatus = apiStatus.Pending;
            
            const {response, error} = await withAsync(getAllRivers, this.riversPerPage, this.riverPage);
            if( error ) {
                this.getAllRiversStatus = apiStatus.Error;
                return error.response.data.message;
            }

                this.getAllRiversStatus = apiStatus.Success;                
                this.paginationLength = response.data.meta.last_page;
                this.rivers = response.data.data;
        },
    },   

    async created() {        
        try {
            await this.fetchPaginatedRivers();
        } catch (error) {
            console.error('Failed to fetch rivers:', error);
        }
    }
    
}

</script>

<template>
    <div class="mt-4">
        <!-- <ul class="scrollable-river-list">
            <li
                @click="selectRiverById( river.river_id )"
                v-for="(river, index) in this.rivers"
                :key="index"
            >
            <BaseMenuListItem
                    :label="river.river_name"
                />     
            </li>
        </ul> -->
        <h3 class="mx-4 my-4 white--text">Regions of interest</h3>
        <v-skeleton-loader 
            v-if="getAllRiversStatus_Pending || getAllRiversStatus_Error"
            v-for="(n) in riversPerPage"
            :key="n"
            class="mx-auto"
            max-width="300"
            type="list-item"            
        ></v-skeleton-loader>        
        <ul class="scrollable-river-list">            
            <li 
            @click="selectRiverById( river.river_id, river.river_name )" 
            v-for="(river, index) in rivers" 
            :key="index"
            :class="{ 'active-river': river.river_id === activeRiverId }">

                <BaseMenuListItem
                    :label="river.river_name"
                />

             </li>
            </ul>
            <div class="mt-4" v-if="getAllRiversStatus_Success">                
                <v-pagination 
                    v-model="riverPage" 
                    :total-visble="riversPerPage" 
                    :length="paginationLength" 
                    @input="fetchPaginatedRivers"
                    @next="fetchPaginatedRivers" 
                    @previous="fetchPaginatedRivers"
                    ></v-pagination>
            </div>

        <!--
        <v-row>
            <v-col
            lg="8"
            md="8"
            cols="12"
            >
            <v-select
                v-model="selectedFilter"
                :items="filters"
                :item-text="filter => filter.title"
                :item-value="filter => filter.value"
                label="Sort by"
                dark
                class="white--text ml-7"
                @input="getRegisteredUsers()"
            ></v-select>
            </v-col>
            <v-col
            class="d-flex align-center ml-sm-2"
            >
            <v-tooltip right>
                <template #activator="{ on }">
                <v-btn
                    elevation="0"
                    color="transparent"
                    dense
                    v-on="on"
                    @click="changeOrder()"
                >
                    <v-icon class="white--text">mdi-swap-vertical</v-icon>
                </v-btn>
                </template>
                <span>Change Order of Results</span>
            </v-tooltip>
            </v-col>
        </v-row>
        
        <h3
            v-if="getAllUsers_Pending"
            class="white--text font-italic text-center mt-10"
        >Loading...</h3>
        
        <v-tooltip right v-if="currentPage !== 1 && getAllUsers_Success">
            <template v-slot:activator="{ on }">
            <v-btn
                elevation="0"
                color="transparent"
                class="arrow-btn"
                @click="currentPage = 1, getRegisteredUsers()"
                v-on="on"
            >
                <v-icon class="white--text text-h3">mdi-chevron-double-up</v-icon>
            </v-btn>
            </template>
            <span>Go to first page</span>
        </v-tooltip>

        <v-tooltip right v-if="currentPage !== 1 && getAllUsers_Success">
            <template v-slot:activator="{ on }">
            <v-btn
                elevation="0"
                color="transparent"
                class="arrow-btn"
                @click="currentPage--, getRegisteredUsers()"
                v-on="on"
            >
                <v-icon class="white--text text-h3">mdi-chevron-up</v-icon>
            </v-btn>
            </template>
            <span>Go to previous page</span>
        </v-tooltip>

        <v-list-item
            v-for="item in items"
            :key="item.id"
            link
            :class="{ 'selected': selectedUserId === item.id }" 
            class="pl-7"
            @click="navigateToAdminPanel(item.id)"
        >
            <v-list-item-icon>
            <v-icon
                class="white--text"
                :class="{ 'selection': selectedUserId === item.id }" 
            >mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content 
            class="white--text font"
            :class="{ 'selection': selectedUserId === item.id }" 
            >
            {{ item.title }}
            </v-list-item-content>
        </v-list-item>

        <v-tooltip right v-if="currentPage !== lastPage && getAllUsers_Success">
            <template v-slot:activator="{ on }">
            <v-btn
                elevation="0"
                color="transparent"
                class="arrow-btn"
                @click="currentPage++, getRegisteredUsers()"
                v-on="on"
            >
                <v-icon class="white--text text-h3">mdi-chevron-down</v-icon>
            </v-btn>
            </template>
            <span>Go to next page</span>
        </v-tooltip>

        <v-tooltip right v-if="currentPage !== lastPage && getAllUsers_Success">
            <template v-slot:activator="{ on }">
            <v-btn
                elevation="0"
                color="transparent"
                class="arrow-btn"
                @click="currentPage = lastPage, getRegisteredUsers()"
                v-on="on"
            >
                <v-icon class="white--text text-h3">mdi-chevron-double-down</v-icon>
            </v-btn>
            </template>
            <span>Go to last page</span>
        </v-tooltip>
      -->
    </div>    
</template>

<style scoped>
 ::v-deep(.theme--light.v-skeleton-loader .v-skeleton-loader__list-item) {
  background-color: unset;
}

    .scrollable-river-list {
        list-style-type: none;
        /* overflow-y: auto;
        height: 100%; */
        padding: 0;
    }

    .scrollable-river-list li:not(:last-child) {
        padding: 10px;
        border-bottom: 1px solid #ffffff2f;
        cursor: pointer;
        color: white;
        padding: 0px 0px;
    }

    .scrollable-river-list li:hover, .active-river {
        background-color: #f9f9f93f;
    }
</style>
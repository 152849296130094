<template>
    <v-container>
        <!-- <h3 class="mb-6">
            Export my classifications from a range of dates
        </h3>

        <v-row class="align-center">
            <pre>{{ selectedDateRange }}</pre>
            <v-col cols="6">
                <date-range-picker
                    ref="picker"
                    opens="right"
                    :disabled="exportAllDateRange"
                    :minDate="null"
                    :maxDate="null"
                    :singleDatePicker="false"
                    :timePicker="false"
                    :timePicker24Hour="false"
                    :showWeekNumbers="true"
                    :showDropdowns="true"
                    :autoApply="true"
                    locale="{
                        format='mm/dd/yyyy'
                    }"
                    v-model="selectedDateRange"
                    @update="onDateSelected"
                    class="ma-5"
                ></date-range-picker>
            </v-col>
            <v-col cols="6">
                <v-checkbox v-model="exportAllDateRange" label="Export All Dates"></v-checkbox>
            </v-col>
        </v-row>

        <v-row>
            <v-btn
                @click="downlaodRange"
                color="primary"                
                :loading="exportRangeStatus_Pending"
                class="text-capitalize ma-3"
            >
                Export classifications 
                <span v-if="exportAllDateRange"> from all dates</span>
                <v-icon right>mdi-download</v-icon>
            </v-btn>
        </v-row>-->
    </v-container> 
</template>

<script>
    import { withAsync } from "@/helpers/withAsync";
    import { apiStatus } from               "@/api/constants/apiStatus";
    import { apiStatusComputed } from "@/api/helpers/computedApiStatus";
    import { mapGetters } from "vuex";
    import { getSingleUserRangeMetadata } from  "@/api/adminApi.js";
    import DateRangePicker from           'vue2-daterange-picker'
    import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
    import { formatDate } from "@/helpers/dateFormatter.js"

    export default {
        name: 'ExportRange',
        components: { DateRangePicker },

        data() {
            return {
                selectedDateRange: {
                    startDate: new Date(),    // or another default start date
                    endDate: new Date(),      // or another default end date
                },
                exportAllDateRange: false,
                exportRangeStatus: apiStatus.Idle,
                exportRangeStatus: false,
            }
        },
        computed: {
            ...apiStatusComputed("exportRangeStatus"),
            ...mapGetters(["getCurrentUser"]),
            
            dateRangeText () {
                return "-"; //this.dates.join(' ~ ')
            },
        },

        methods: {
            onDateSelected( selectedDate ) {
                this.selectedDateRange.startDate = selectedDate.startDate;
                this.selectedDateRange.endDate = selectedDate.endDate;
            },

            async downlaodRange() {
                this.exportRangeStatus = apiStatus.Pending;
                const payload = {
                    start_date: formatDate(this.selectedDateRange.startDate),
                    end_date: formatDate(this.selectedDateRange.endDate)
                };

                const { response, error } = await withAsync(getSingleUserRangeMetadata, payload, { responseType: 'blob' });
                
                if(error) {
                    this.$emit("export-error", { "error": true, "errorMessage": "No satellite imagery found for the specified dates." })
                    this.exportRangeStatus = apiStatus.Error;
                    this.dates = [];
                    return;
                }

                var FILE = window.URL.createObjectURL(new Blob([response.data], {type: "application/vnd.ms-excel"}));
                var docUrl = document.createElement('a');
                docUrl.href = FILE;
                docUrl.setAttribute('download', `classifications_by_${this.getCurrentUser}_for_${this.dateRangeText}.xlsx`);
                document.body.appendChild(docUrl);
                docUrl.click();
                this.exportRangeStatus = apiStatus.Success;
            },
        }
    }
    
</script>

<style scoped>
    .daterangepicker .ranges {
        display: none !important;
    }
</style>
export const formatDate = (date) => {
    // Format year, month, and day separately
    const yearFormatter = new Intl.DateTimeFormat("en", {
        year: "numeric",
    }).format(date);
    const monthFormatter = new Intl.DateTimeFormat("en", {
        month: "2-digit",
    }).format(date);
    const dayFormatter = new Intl.DateTimeFormat("en", {
        day: "2-digit",
    }).format(date);

    // Concatenate the formatted components in the desired order
    const formattedDate = `${yearFormatter}-${monthFormatter}-${dayFormatter}`;

    console.log("Formatted Date: ", formattedDate);
    return formattedDate;
};
